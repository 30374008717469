import { signal, Signal } from '@angular/core';
import { DsHeaderUserInfo } from '@bmw-ds/components';
import { UserAccountProvider } from 'src/app/authentication/model/user-account-provider';

export class StaticUserAccountService implements UserAccountProvider {
  userInfo: Signal<DsHeaderUserInfo | undefined> = signal({
    department: 'FG',
    strongAuth: false,
    username: 'BMW User',
  });

  logout(): void {}
}
