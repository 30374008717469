import { Component, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import {
  DsBoxModule,
  DsButtonModule,
  DsHeaderModule,
  DsNavigationBarModule,
  DsNavigationItem,
  DsSwitchModule,
  DsThemeService,
} from '@bmw-ds/components';
import { map, switchMap, timer } from 'rxjs';
import { HealthCheckApiService } from 'src/app/core/services/health-check-api.service';
import { environment } from '../environments/environment';
import { UserAccountService } from 'src/app/authentication/services/user-account.service';

@Component({
  imports: [
    RouterOutlet,
    DsNavigationBarModule,
    DsHeaderModule,
    DsBoxModule,
    DsButtonModule,
    DsSwitchModule,
  ],
  selector: 'app-root',
  styleUrl: './app.component.scss',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private healthCheckApiService: HealthCheckApiService,
    private userAccountService: UserAccountService,
    private titleService: Title,
    private themeService: DsThemeService
  ) {
    this.titleService.setTitle(this.title);
  }

  private HEALTH_CHECK_INTERVAL = 60000;
  environmentTagConfig: WritableSignal<Partial<string>> = signal(
    environment.name
  );
  apiStatus = toSignal(
    timer(0, this.HEALTH_CHECK_INTERVAL).pipe(
      switchMap(() => this.healthCheckApiService.getHealthCheckStatus()),
      map((result) => {
        return result.isLoading
          ? $localize`Checking`
          : result.map((health) => health.status).unwrapOr('Unavailable');
      })
    ),
    { initialValue: $localize`Unknown` }
  );

  userInfo = this.userAccountService.userInfo;

  navigationItems = signal<DsNavigationItem[]>([
    {
      icon: 'search',
      label: $localize`Vehicles`,
      routerLink: '/vehicles',
    },
  ]);

  isDarkThemeEnabled = signal(this.themeService.isDark);

  title = $localize`Pricing | UVR Evo`;

  public logout(): void {
    this.userAccountService.logout();
  }

  public toggleTheme(): void {
    this.themeService.toggleDarkTheme();
    this.isDarkThemeEnabled.set(this.themeService.isDark);
  }
}
