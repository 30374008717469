import {
  authInitializerFactory,
  FEDEV_AUTH_INIT_OPTIONS,
  FedevAuthService,
  provideFedevAuth,
} from '@bmw-fedev/auth';
import { environment } from 'src/environments/environment';
import { DsImprintModule } from '@bmw-ds/components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApplicationConfig,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  makeEnvironmentProviders,
  provideAppInitializer,
} from '@angular/core';
import {
  provideRouter,
  RouteReuseStrategy,
  withComponentInputBinding,
} from '@angular/router';

import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AutoLogoutInterceptor } from 'src/app/authentication/interceptors/auto-logout-interceptor';
import { StoreRouteReuseStrategy } from 'src/app/core/strategies/store-route-reuse-strategy';
import { detectScenario } from 'src/app/core/helpers/detect-scenario';
import { createScenarioHeaderInterceptorForScenario } from 'src/app/static/interceptors/scenario-header.interceptor';
import { environmentWithFedevAuth } from 'src/data-contract/schemas/environmentSchema';
import { StaticUserAccountService } from 'src/app/static/services/static-user-account.service';
import { UserAccountService } from 'src/app/authentication/services/user-account.service';

const provideAuthentication = (): EnvironmentProviders => {
  const parsedAuthEnvironment = environmentWithFedevAuth.safeParse(environment);

  if (parsedAuthEnvironment.success) {
    const authEnv = parsedAuthEnvironment.data;
    return makeEnvironmentProviders([
      provideFedevAuth({
        env: authEnv,
        includeAccessTokenInBackendRequests: true,
      }),
      provideAppInitializer(() =>
        authInitializerFactory(
          inject(FedevAuthService),
          inject(FEDEV_AUTH_INIT_OPTIONS)
        )()
      ),
      {
        provide: FEDEV_AUTH_INIT_OPTIONS,
        useValue: {
          config: {
            clientId: authEnv.clientId,
            issuer: authEnv.issuer,
            oidc: true,
            preserveRequestedRoute: true,
            redirectUri: globalThis.location.origin,
            responseType: 'code',
            scope: 'openid profile email groups',
            skipIssuerCheck: false,
          },
        },
      },
    ]);
  }

  return makeEnvironmentProviders([
    {
      provide: UserAccountService,
      useClass: StaticUserAccountService,
    },
  ]);
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAuthentication(),
    importProvidersFrom(
      DsImprintModule.forRoot({
        electronicContact: 'change-me@bmwgroup.com',
        phone: '5555555',
      }),
      BrowserAnimationsModule
    ),
    provideRouter(routes, withComponentInputBinding()),
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AutoLogoutInterceptor,
    },
    {
      provide: RouteReuseStrategy,
      useExisting: StoreRouteReuseStrategy,
    },
    provideHttpClient(
      environment.name === 'static'
        ? withInterceptors([
            createScenarioHeaderInterceptorForScenario(
              detectScenario(globalThis.location.search)
            ),
          ])
        : withInterceptorsFromDi()
    ),
  ],
};
