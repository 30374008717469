<ds-header
  [navBarRef]="navBar"
  appName="{{ title }}"
  [environmentTag]="{
    label: environmentTagConfig() + ' - ' + 'Backend Status: ' + apiStatus(),
  }"
  [userInfo]="userInfo()"
  (requestLogout)="logout()"
>
  <ds-switch
    (ngModelChange)="toggleTheme()"
    [ngModel]="isDarkThemeEnabled()"
    i18n
  >
    Dark Theme
  </ds-switch>
</ds-header>

<div class="d-flex">
  <div class="nav-bar-wrapper position-sticky">
    <ds-navigation-bar
      class="nav-bar-wrapper position-sticky"
      #navBar
      [items]="navigationItems()"
    >
    </ds-navigation-bar>
  </div>
  <router-outlet></router-outlet>
</div>
