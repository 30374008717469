import {
  authInitializerFactory,
  FEDEV_AUTH_INIT_OPTIONS,
  FedevAuthService,
  provideFedevAuth,
} from '@bmw-fedev/auth';
import { environment } from 'src/environments/environment';
import { DsImprintModule } from '@bmw-ds/components';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [
    provideFedevAuth({
      env: environment,
      includeAccessTokenInBackendRequests: true,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializerFactory,
      deps: [FedevAuthService, FEDEV_AUTH_INIT_OPTIONS],
      multi: true,
    },
    {
      provide: FEDEV_AUTH_INIT_OPTIONS,
      useValue: {
        config: {
          issuer: environment.issuer,
          redirectUri: window.location.origin,
          preserveRequestedRoute: true,
          clientId: environment.clientId,
          responseType: 'code',
          scope: 'openid profile email groups',
          skipIssuerCheck: false,
          oidc: true,
        },
      },
    },
    importProvidersFrom(
      DsImprintModule.forRoot({
        phone: '5555555',
        electronicContact: 'change-me@bmwgroup.com',
      }),
      BrowserAnimationsModule
    ),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptorsFromDi()),
  ],
};
