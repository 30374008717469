import {
  ScenarioKey,
  scenarioKeySchema,
} from 'src/data-contract/schemas/scenarioKey';

export const detectScenario = (queryString: string): ScenarioKey | null => {
  const urlSearchParams = new URLSearchParams(queryString);
  const scenario = urlSearchParams.get('scenario');
  const parsedScenario = scenarioKeySchema.safeParse(scenario);

  return parsedScenario.success ? parsedScenario.data : null;
};
