import { Component, signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import {
  DsBoxModule,
  DsButtonModule,
  DsHeaderModule,
  DsHeaderUserInfo,
  DsNavigationBarModule,
  DsNavigationItem,
} from '@bmw-ds/components';
import { map, switchMap, timer } from 'rxjs';
import { HealthCheckApiService } from 'src/app/core/services/health-check-api.service';
import { environment } from '../environments/environment';
import { FedevAuthService } from '@bmw-fedev/auth';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    DsNavigationBarModule,
    DsHeaderModule,
    DsBoxModule,
    DsButtonModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private HEALTH_CHECK_INTERVAL = 60000;
  environmentTagConfig: WritableSignal<Partial<string>> = signal(
    environment.name
  );
  apiStatus = toSignal(
    timer(0, this.HEALTH_CHECK_INTERVAL).pipe(
      switchMap(() => this.healthCheckApiService.getHealthCheckStatus())
    )
  );

  logout() {
    this.fedevAuthService.logout();
  }

  userInfo = toSignal<DsHeaderUserInfo>(
    this.fedevAuthService.userProfile$.pipe(
      switchMap((userProfile: { departmentnumber?: string; acr?: string }) =>
        this.fedevAuthService.currentUser$.pipe(
          map((currentUser) => ({
            username: currentUser ?? $localize`Not logged in`,
            department: userProfile.departmentnumber ?? $localize`N/A`,
            strongAuth: parseInt(userProfile.acr ?? '0') > 1,
          }))
        )
      )
    )
  );

  constructor(
    private healthCheckApiService: HealthCheckApiService,
    private fedevAuthService: FedevAuthService,
    private titelService: Title
  ) {
    this.titelService.setTitle(this.title);
  }

  navigationItems = signal<DsNavigationItem[]>([
    {
      label: $localize`Vehicles`,
      href: '/vehicles',
      icon: 'search',
    },
  ]);

  title = $localize`Pricing | UVR Evo`;
}
