import { HttpInterceptorFn } from '@angular/common/http';
import { ScenarioKey } from 'src/data-contract/schemas/scenarioKey';

export const createScenarioHeaderInterceptorForScenario = (
  scenario: ScenarioKey | null
): HttpInterceptorFn => {
  return (req, next) => {
    if (scenario !== null) {
      const reqWithScenarioHeader = req.clone({
        headers: req.headers.set('x-scenario', scenario),
      });

      return next(reqWithScenarioHeader);
    }

    return next(req);
  };
};
