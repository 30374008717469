import { Route } from '@angular/router';

export interface AppRoute extends Route {
  data?: {
    storeRoute?: boolean;
  };
}

export const routes: AppRoute[] = [
  {
    data: {
      storeRoute: true,
    },
    loadComponent: () =>
      import(
        'src/app/pricing/components/pricing-page/pricing-page.component'
      ).then((c) => c.PricingPageComponent),
    path: 'vehicles',
  },
  {
    data: {
      storeRoute: false,
    },
    loadComponent: () =>
      import(
        'src/app/pricing/components/details-page/details-page.component'
      ).then((c) => c.DetailsPageComponent),
    path: 'vehicles/:vin',
  },
  { path: '', pathMatch: 'full', redirectTo: 'vehicles' },
  { path: '**', redirectTo: 'vehicles' },
];
