import { Injectable } from '@angular/core';
import { DsHeaderUserInfo } from '@bmw-ds/components';
import { toSignal } from '@angular/core/rxjs-interop';
import { map, switchMap } from 'rxjs';
import { FedevAuthService } from '@bmw-fedev/auth';
import { UserAccountProvider } from 'src/app/authentication/model/user-account-provider';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService implements UserAccountProvider {
  constructor(private fedevAuthService: FedevAuthService) {}

  userInfo = toSignal<DsHeaderUserInfo>(
    this.fedevAuthService.userProfile$.pipe(
      switchMap((userProfile: { acr?: string; departmentnumber?: string }) =>
        this.fedevAuthService.currentUser$.pipe(
          map((currentUser) => ({
            department: userProfile.departmentnumber ?? $localize`N/A`,
            strongAuth: parseInt(userProfile.acr ?? '0') > 1,
            username: currentUser ?? $localize`Not logged in`,
          }))
        )
      )
    )
  );

  logout(): void {
    this.fedevAuthService.logout();
  }
}
