import { z } from 'zod';
import { IFedevAuthEnvInterface } from '@bmw-fedev/auth/models/auth.interface';

export const environmentNameSchema = z.union([
  z.literal('development'),
  z.literal('qa'),
  z.literal('static'),
  z.literal('production'),
]);

export const environmentSchema = z.object({
  apiUrl: z.string(),
  name: environmentNameSchema,
  production: z.boolean(),
  quickSightPricingDashboardUrl: z.string(),
});

export type Environment = z.infer<typeof environmentSchema>;

export const environmentWithFedevAuth: z.ZodSchema<
  IFedevAuthEnvInterface & Environment
> = environmentSchema.extend({
  clientId: z.string(),
  issuer: z.string(),
});

export type EnvironmentWithFedevAuth = z.infer<typeof environmentWithFedevAuth>;
