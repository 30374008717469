/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { ModuleRegistry } from 'ag-grid-community';
import { AllEnterpriseModule } from 'ag-grid-enterprise';

// Register AG grid modules
ModuleRegistry.registerModules([AllEnterpriseModule]);

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
